import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Dropdown,
    Grid,
    GridCol,
    Heading,
    Infobox,
    NavHorizontal,
    NavItem,
    Spinner,
} from '@flixbus/honeycomb-react';
import {
    Icon,
    IconChatAlt,
    IconNewsletter,
    IconNotification,
    IconPlus,
    IconWhatsapp,
} from '@flixbus/honeycomb-icons-react';
import { useTemplate } from '../../../hooks';
import TemplatesList from '../../TemplatesList';
import { TranslateContext } from '../../../system/Translate';
import Filters from '../../Filters';
import * as FILTERS from '../../Filters/FiltersList';
import isDateBetween from '../../../helpers/isDateBetween';
import UserCan from '../../../auth/UserCan';
import useGroupedData from '../../../hooks/useGroupedData';
import CloudFilters from '../../CloudFilters';
import { useCloudFilters } from '../context/CloudFiltersContext';

export const SHOP_GROUPS = {
    gh: 'GH',
    flix: 'FLIX',
    kk: 'KK',
    flixTrain: 'FLIX_TRAIN',
}

export default function HomeSection() {
    const { translate } = useContext(TranslateContext);
    const { isLoading, isError, error, data } = useTemplate();
    const groupedData = useGroupedData(data);
    const [templates, setTemplates] = useState(data);
    const { filters, addFilter } = useCloudFilters();
    const [shopGroup, setShopGroup] = useState('');

    const createLinks = [
        {
            text: (
                <span>
                    <Icon InlineIcon={IconNewsletter} size={4} />
                    &nbsp;
                    {translate('email-template')}
                </span>
            ),
            to: `/create/email/${shopGroup}`,
            key: 'email',
            RouterLink: Link,
        },
        {
            text: (
                <span>
                    <Icon InlineIcon={IconChatAlt} size={4} />
                    &nbsp;
                    {translate('sms-template')}
                </span>
            ),
            to: `/create/sms/${shopGroup}`,
            key: 'sms',
            RouterLink: Link,
        },
        {
            text: (
                <span>
                    <Icon InlineIcon={IconWhatsapp} size={4} />
                    &nbsp;
                    WhatsApp template
                </span>
            ),
            to: `/create/whatsapp/${shopGroup}`,
            key: 'whatsapp',
            RouterLink: Link,
        },
        {
            text: (
                <span>
                    <Icon InlineIcon={IconNotification} size={4} />
                    &nbsp;
                    Push template
                </span>
            ),
            to: `/create/push/${shopGroup}`,
            key: 'push',
            RouterLink: Link,
        },
    ];

    const onShopChange = (shopName) => {
        addFilter(FILTERS.SHOP_GROUP, shopName, true);
    };

    useEffect(() => {
        if(filters?.shopGroup) {
            setShopGroup(...filters.shopGroup);
        }
    }, [filters])

    useEffect(() => {
        if (data) {
            const filteredTemplates = data.filter((template) => {
                return Object.entries(filters).every(([filterName, searchValues]) => {
                    if (!searchValues || searchValues.size === 0) {
                        return true;
                    }

                    if (filterName === FILTERS.UPDATED_AT) {
                        return Array.from(searchValues).some((rangeStr) => {
                            const [startStr, endStr] = rangeStr.split(' - ');
                            return isDateBetween(startStr, endStr, template.updatedAt);
                        });
                    }

                    if(filterName === FILTERS.SHOP_GROUP) {
                        return Array.from(searchValues).some((searchVal) =>
                            template.shopGroup?.toLowerCase() === (searchVal.toLowerCase())
                        );
                    }

                    return Array.from(searchValues).some((searchVal) =>
                        template[filterName]?.toLowerCase().includes(searchVal.toLowerCase())
                    );
                });
            });

            setTemplates(filteredTemplates);
        }
    }, [data, filters])

    return (
        <Grid>
            <GridCol size={12} xl={11} pushXl={1} lg={11} pushLg={1} extraClasses="table-grid-wrapper">
                <div className="cn-heading">
                    <Grid justify="right" align="center">
                        <GridCol>
                            <Heading size={2}>{translate('home.title')}</Heading>
                        </GridCol>
                        <GridCol>
                            <div className="cn-heading-controls">
                                <UserCan
                                    perform="add_template"
                                    yes={() => (
                                        <Dropdown links={createLinks} xPosition="left">
                                            <Button
                                                aria-label="dropdown"
                                                appearance="primary"
                                                Elem="button"
                                                display="square"
                                            >
                                                <Icon InlineIcon={IconPlus} />
                                            </Button>
                                        </Dropdown>
                                    )}
                                />
                            </div>
                        </GridCol>
                    </Grid>
                    <CloudFilters />
                    <Filters
                        groupedData={groupedData}
                        templates={data}
                    />
                </div>

                {isLoading ? <Spinner size="xl" /> : null}
                {isError && <Infobox appearance="danger">{String(error)}</Infobox>}
                {templates ? (
                    <>
                        <NavHorizontal aria-label="Shop Group">
                            <NavItem
                                onClick={() => onShopChange(SHOP_GROUPS.flix)}
                                active={filters?.shopGroup?.has(SHOP_GROUPS.flix)}
                            >
                                FLIX
                            </NavItem>
                            <NavItem
                                onClick={() => onShopChange(SHOP_GROUPS.gh)}
                                active={filters?.shopGroup?.has(SHOP_GROUPS.gh)}
                            >
                                GH
                            </NavItem>
                            <NavItem
                                onClick={() => onShopChange(SHOP_GROUPS.kk)}
                                active={filters?.shopGroup?.has(SHOP_GROUPS.kk)}
                            >
                                KK
                            </NavItem>
                            <NavItem
                                onClick={() => onShopChange(SHOP_GROUPS.flixTrain)}
                                active={filters?.shopGroup?.has(SHOP_GROUPS.flixTrain)}
                            >
                                FlixTrain
                            </NavItem>
                        </NavHorizontal>
                        <TemplatesList data={templates} />
                    </>

                ) : null}
            </GridCol>
        </Grid>
    );
}

